<template>
  <!-- 改了 -->
  <div class="home" :class="show ? 'ish5' : ''">
    <div class="htop">
      <mtop />
      <div ref="mySwiper" class="swiper-container banner" id="banner">
        <div class="swiper-wrapper">
          <template v-if="swiperList.length > 0">
            <div
              class="swiper-slide"
              v-for="(item, index) in swiperList"
              :key="index"
              @click="toNews(item)"
            >
              <img :src="item.imgUrl" alt="" />
              <div class="banimg">
                <!-- <div class="title">智能客服</div> -->
                <div class="tip">
                  {{ item.description }}
                </div>
                <div class="btn-box">
                  <div
                    class="btn"
                    v-if="item.button1Enable == 1"
                    @click.stop="tosomePage(item)"
                  >
                    {{ item.button1Name }}
                  </div>
                  <div
                    class="btn2"
                    v-if="item.button2Enable == 1"
                    @click.stop="tosomePage2(item)"
                  >
                    {{ item.button2Name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- <template v-else>
            <div class="swiper-slide">
              <img src="@/assets/sdImages/home/banner.png" alt="" />
              <div class="banimg">
                <div class="title">智能客服</div>
                <div class="tip">
                  智能客服满足客户对内队外全渠道智能服务场景，
                  <br />赋能企业降本增效，搭建智能客服体系
                </div>
                <div class="btn-box">
                  <div class="btn">方案咨询</div>
                  <div class="btn2">预约演示</div>
                </div>
              </div>
            </div>
          </template> -->

          <!-- <div class="swiper-slide">
            <img src="@/assets/sdImages/home/banner.png" alt="" @click="toOpen('http://wkz.xingrunkeji.cn')" />
            <div class="banimg">
              <div class="title">智能客服</div>
              <div class="tip">
                智能客服满足客户对内队外全渠道智能服务场景，
                <br />赋能企业降本增效，搭建智能客服体系
              </div>
              <div class="btn-box">
                <div class="btn">方案咨询</div>
                <div class="btn2">预约演示</div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/sdImages/home/banner.png" alt="" />
            <div class="banimg">
              <div class="title">智能客服</div>
              <div class="tip">
                智能客服满足客户对内队外全渠道智能服务场景，
                <br />赋能企业降本增效，搭建智能客服体系
              </div>
              <div class="btn-box">
                <div class="btn">方案咨询</div>
                <div class="btn2">预约演示</div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/sdImages/home/banner.png" alt="" />
            <div class="banimg">
              <div class="title">智能客服</div>
              <div class="tip">
                智能客服满足客户对内队外全渠道智能服务场景，
                <br />赋能企业降本增效，搭建智能客服体系
              </div>
              <div class="btn-box">
                <div class="btn">方案咨询</div>
                <div class="btn2">预约演示</div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <!-- 产品及服务项目 -->
    <div class="content">
      <div class="box">
        <div class="btop">
          <div class="title">产品与应用系统</div>
          <div class="tips">以用户为中心，提供智能化产品赋能企业数智化转型</div>
        </div>
        <div class="cont tscont">
          <div class="card tsCard upCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>智能质检</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">智能质检</p>
                <p class="fadeIn p2">
                  深入理解客服会话内容，通过人工质检与AI智能质检的结合构建智能质检管控体系。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('智能质检', '/quality-check')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card tsCard2 downCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>智能外呼机器人</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">智能外呼机器人</p>
                <p class="fadeIn p2">
                  通过人工智能算法自动进行电话外呼，提高销售效率，减少人工成本。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('智能外呼机器人', '/outbound')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card tsCard3 upCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>智能电话机器人</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">智能电话机器人</p>
                <p class="fadeIn p2">
                  7*24H无休，精准识别客户意图，自动回答客户问题，提供咨询和解决方案。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('智能电话机器人', '/phone-robot')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card tsCard4 downCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>坐席助手</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">坐席助手</p>
                <p class="fadeIn p2">
                  利用人工智能技术辅助客服人员工作，提高工作效率和客户满意度。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('坐席助手', '/seat')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card tsCard5 upCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>文字客服机器人</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">文字客服机器人</p>
                <p class="fadeIn p2">
                  通过人工智能算法自动回复客户咨询，提供快速、准确的解答。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('文字客服机器人', '/text-robot')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card tsCard6 downCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>分布式视频监控平台</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">分布式视频监控平台</p>
                <p class="fadeIn p2">
                  主要为商业服务、家庭看管、智慧校园行政推动等类型客户提供实时视频浏览云台控制对讲等功能。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('分布式视频监控平台', '/case1')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card tsCard7 upCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>私有化大模型</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">私有化大模型</p>
                <p class="fadeIn p2">
                  建立私有化大模型，与客户共同创建行业垂类大模型，全面赋能企业客户智能化转型。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('私有化大模型', '/gpt-private')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card tsCard8 downCard">
            <!-- <div class="header">
							<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
							<div class="title">NFT数藏系统</div>
						</div> -->
            <div class="titleAndBtn">
              <p>大模型应用</p>
              <img src="@/assets/sdImages/home/jiantou-copy3.png" alt="" />
            </div>
            <div class="cbox">
              <!-- <span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span> -->
              <!-- <div class="btn fadeIn" @click="toPage('/collection')">立即了解</div> -->
              <div class="cbox-content">
                <p class="fadeIn p1">大模型应用</p>
                <p class="fadeIn p2">
                  利用大模型的能力实现RAG知识库、Agent等应用，增强产品能力，重塑产品与商业模式。
                </p>
                <!-- <img src="@/assets/sdImages/home/jiantou-copy2.png" alt="" /> -->
                <div class="btns">
                  <div class="free-btn" @click="clickMenu('大模型应用', '/gpt-app')">查看详情</div>
                  <!-- <div class="detail-btn">查看详情</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="content hbg">
      <div class="box">
        <div class="btop">
          <div class="title">行业解决方案</div>
          <div class="tips">用可信、高效的技术方案助力产业协作数字化升级</div>
        </div>
        <div class="case">
          <div class="case-tab">
            <div ref="myCase" class="swiper-container Gwidth" id="case">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <p
                    class="case-nav"
                    :class="{ active: current == 0 }"
                    @click="(current = 0), getCase()"
                  >
                    智慧金融
                  </p>
                </div>
                <div
                  class="swiper-slide"
                  v-for="(item, index) in caseNav"
                  :key="item.id"
                >
                  <p
                    class="case-nav"
                    :class="{ active: current == index + 1 }"
                    @click="(current = index + 1), getCase()"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="case-content Gwidth">
            <div class="case-content-text">
              <template v-if="current == 0">
                <p class="p1">全渠道智能客服解决方案</p>
                <p class="p2">
                  客户联络一体化解决方案，覆盖触达-营销-服务-留存，打造业务全闭环。通过多渠道布局，通过智能机器人和大数据提升金融行业客户服务效率与能力。
                </p>
              </template>
              <template v-if="current == 1">
                <p class="p1">政府大数据解决方案</p>
                <p class="p2">
                  提供数据治理、建模加工、场景应用、流通交易等数据要素全生命周期管理服务。打通数据孤岛、构建数据资产、生产数据产品、打造数据应用场景、盘活政务数据资源。
                </p>
              </template>
              <template v-if="current == 2">
                <p class="p1">智慧农业平台解决方案</p>
                <p class="p2">
                  综合利用物联网、大数据、人工智能等现代信息技术，通过构建数据驱动的农业生产、管理和服务体系，提高农业生产效率、优化资源配置。推动农业产业链的数字化转型。
                </p>
              </template>
              <template v-if="current == 3">
                <p class="p1">智慧文旅解决方案</p>
                <p class="p2">
                  聚焦智慧旅游发展关键技术，以科技创新提升文旅数字化水平。发挥工智能、地图、搜索、虚拟（增强）现实、大数据等核心能力为文旅管理、服务和运营赋能，提供基于全流程服务和管理的智慧文旅应用体系。
                </p>
              </template>
              <template v-if="current == 4">
                <p class="p1">全媒体联络中心解决方案</p>
                <p class="p2">
                  为企业打造“全媒体、全业务、智能化”的联络与营销中心。融合音视频、人工智能等技术，提供机器人客服、座席辅助、通话质检等能力，优化用户联络体验，为客户提供全方位的智能支撑
                </p>
              </template>
              <template v-if="current == 5">
                <p class="p1">智慧园区解决方案</p>
                <p class="p2">
                  通过AIoT物联网技术实现设备感知、接入、管理及智能分析，构建统一的智慧园区管理系统。从建设到运营发展，全面覆盖园区的各项业务，助力建成人、物、空间全面联动的新型智慧园区。
                </p>
              </template>
              <template v-if="current == 6">
                <p class="p1">智能客服营销一体化解决方案</p>
                <p class="p2">
                  面向汽车营销服务场景，利用智能客服的全栈服务能力以及标准化的产品矩阵，灵活的产品搭配，无缝链接各业务系统，随时结合业务场景构建功能体系，帮助车企大幅缩短平台构建时间，保证营销服务活动顺利开展。
                </p>
              </template>
              <p
                class="p3"
                @click="(dialogVisible = true), clickMenu('免费试用')"
              >
              免费试用<img
                  src="@/assets/sdImages/home/Group1565.png"
                  alt=""
                />
              </p>
              <div class="attr">
                <div class="attr-info">
                  <img src="@/assets/sdImages/home/duihao-25.png" alt="" />
                  <div class="title" v-show="current == 0">全渠道覆盖</div>
                  <div class="title" v-show="current == 1">数据治理智能化</div>
                  <div class="title" v-show="current == 2">一体化产服平台</div>
                  <div class="title" v-show="current == 3">智慧服务</div>
                  <div class="title" v-show="current == 4">多渠道整合</div>
                  <div class="title" v-show="current == 5">智能化管理</div>
                  <div class="title" v-show="current == 6">客户数据整合</div>
                </div>
                <div class="attr-info">
                  <img src="@/assets/sdImages/home/duihao-25.png" alt="" />
                  <div class="title" v-show="current == 0">全时段智能接待</div>
                  <div class="title" v-show="current == 1">数据建模产品化</div>
                  <div class="title" v-show="current == 2">高标准农田建设</div>
                  <div class="title" v-show="current == 3">智慧管理</div>
                  <div class="title" v-show="current == 4">智能化服务</div>
                  <div class="title" v-show="current == 5">数据驱动决策</div>
                  <div class="title" v-show="current == 6">个性化营销</div>
                </div>
                <div class="attr-info">
                  <img src="@/assets/sdImages/home/duihao-25.png" alt="" />
                  <div class="title" v-show="current == 0">人机协同</div>
                  <div class="title" v-show="current == 1">数据服务合规化</div>
                  <div class="title" v-show="current == 2">农产品溯源管理</div>
                  <div class="title" v-show="current == 3">智慧营销</div>
                  <div class="title" v-show="current == 4">数据驱动决策</div>
                  <div class="title" v-show="current == 5">生态伙伴聚合</div>
                  <div class="title" v-show="current == 6">多种渠道服务</div>
                </div>
                <div class="attr-info">
                  <img src="@/assets/sdImages/home/duihao-25.png" alt="" />
                  <div class="title" v-show="current == 0">数据洞察分析</div>
                  <div class="title" v-show="current == 1">数据应用场景化</div>
                  <div class="title" v-show="current == 2">全链路数据分析</div>
                  <div class="title" v-show="current == 3">智慧运营</div>
                  <div class="title" v-show="current == 4">个性化客户服务</div>
                  <div class="title" v-show="current == 5">全域安全可控</div>
                  <div class="title" v-show="current == 6">数据洞察分析</div>
                </div>
              </div>
            </div>
            <div class="case-content-img">
              <img
                src="@/assets/sdImages/home/img/01.png"
                alt=""
                v-show="current == 0"
              />
              <img
                src="@/assets/sdImages/home/img/02.png"
                alt=""
                v-show="current == 1"
              />
              <img
                src="@/assets/sdImages/home/img/03.png"
                alt=""
                v-show="current == 2"
              />
              <img
                src="@/assets/sdImages/home/img/04.png"
                alt=""
                v-show="current == 3"
              />
              <img
                src="@/assets/sdImages/home/img/05.png"
                alt=""
                v-show="current == 4"
              />
              <img
                src="@/assets/sdImages/home/img/06.png"
                alt=""
                v-show="current == 5"
              />
              <img
                src="@/assets/sdImages/home/img/07.png"
                alt=""
                v-show="current == 6"
              />
            </div>
          </div>

          <!-- <div class="case-more">
						<router-link to="/gallery">更多精彩案例</router-link>
					</div> -->
        </div>
      </div>
    </div>
    <!--  为什么选择我们 -->
    <div class="content abg">
      <div class="box">
        <div class="btop">
          <div class="title">近期要闻</div>
<!--          <div class="tips">-->
<!--            探索最新行业动态，把握前沿趋势！汇聚善达最新动态、产品发布、市场洞察及深度分析，旨在为您提供最全面、最及时的业内资讯，助力您领先一步，智胜未来。-->
<!--          </div>-->
        </div>
        <div class="cont">
          <!-- <div class="abg-radio">
            <div class="abg-nav" v-for="(item, index) in jqNewsBtns" :key="item.id"
              :class="{ active: current2 == index }" @click="current2 = index, getList(), getList2()">
              {{ item.name }}
            </div>
          </div> -->
          <div class="abg-content">
            <div
              class="abg-left"
              v-for="(item, index) in topList"
              :key="index"
              @click="toPagenews(item)"
            >
              <div class="img">
                <img :src="item.topImgUrl" alt="" />
              </div>
              <div class="abg-left-content">
                <div class="time">{{ item.createTime }}</div>
                <div class="title">
                  {{ item.name }}
                </div>
                <div class="content">
                  {{ item.description }}
                </div>
              </div>
            </div>
            <div class="abg-right">
              <div
                class="abg-right-content"
                v-for="(item, index) in newsList"
                :key="index"
                @click="toPagenews(item)"
              >
                <div class="title">
                  {{ item.name }}
                </div>
                <div class="content">
                  {{ item.description }}
                </div>
                <div class="time-more">
                  <div class="time">{{ item.createTime }}</div>
                  <div class="more">
                    <img
                      src="@/assets/sdImages/home/Group13.png"
                      alt=""
                      class="group13"
                    /><img
                      src="@/assets/sdImages/home/Group14.png"
                      alt=""
                      class="group14"
                    />
                  </div>
                </div>
              </div>

              <!-- <div class="abg-right-content">
                <div class="title">
                  小盒子守护大梦想—善达信息参与六一儿童节公益活动
                </div>
                <div class="content">
                  在六一儿童节即将来临之际，善达信息党支部以实际行动诠释企业的社会责任，积...
                </div>
                <div class="time-more">
                  <div class="time">2023.07.12</div>
                  <div class="more">
                    MORE<img src="@/assets/sdImages/home/Group13.png" alt="" class="group13" /><img
                      src="@/assets/sdImages/home/Group14.png" alt="" class="group14" />
                  </div>
                </div>
              </div>
              <div class="abg-right-content">
                <div class="title">
                  小盒子守护大梦想—善达信息参与六一儿童节公益活动
                </div>
                <div class="content">
                  在六一儿童节即将来临之际，善达信息党支部以实际行动诠释企业的社会责任，积...
                </div>
                <div class="time-more">
                  <div class="time">2023.07.12</div>
                  <div class="more">
                    MORE<img src="@/assets/sdImages/home/Group13.png" alt="" class="group13" /><img
                      src="@/assets/sdImages/home/Group14.png" alt="" class="group14" />
                  </div>
                </div>
              </div>
              <div class="abg-right-content">
                <div class="title">
                  小盒子守护大梦想—善达信息参与六一儿童节公益活动
                </div>
                <div class="content">
                  在六一儿童节即将来临之际，善达信息党支部以实际行动诠释企业的社会责任，积...
                </div>
                <div class="time-more">
                  <div class="time">2023.07.12</div>
                  <div class="more">
                    MORE<img src="@/assets/sdImages/home/Group13.png" alt="" class="group13" /><img
                      src="@/assets/sdImages/home/Group14.png" alt="" class="group14" />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="content hzbg relative-filter">
      <div class="left-zg"></div>
      <div class="right-zg"></div>
      <div class="box">
        <div class="btop">
          <div class="title">合作伙伴</div>
<!--          <div class="tips">-->
<!--            生态合作伙伴/业务合作伙伴展示,旨在突出合作的价值和卓越的业务能力。-->
<!--          </div>-->
        </div>
        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/1.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/2.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/3.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/4.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/5.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/6.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/7.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/8.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/9.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/10.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/11.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/12.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/13.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/14.png" alt="" />
            </div>
          </div>
        </div>

        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper2"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/15.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/16.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/17.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/18.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/19.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/20.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/21.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/22.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/23.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/24.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/25.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/26.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/27.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/28.png" alt="" />
            </div>
          </div>
        </div>

        <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper3"
          ref="myimgSwiper"
        >
          <!-- <div class="Gtitle">合作伙伴</div> -->
          <div class="swiper-wrapper">
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/29.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/30.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/31.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/32.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/33.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/34.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/35.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/36.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/37.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/38.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/39.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/40.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/41.png" alt="" />
            </div>
            <div class="imgBox swiper-slide">
              <img src="@/assets/sdImages/home/some/42.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="last-content">
      <div class="box">
        <div class="last-text">现在，非常期待与您的又一次邂逅</div>
        <div class="last-btn">
          <div class="contant">
            <span>立即联系</span>
          </div>
          <div class="play">
            <span>预约演示</span>
          </div>
        </div>
      </div>
    </div> -->
    <fotterBanner></fotterBanner>
    <mbottom />
    <demonstrate
      :dialogVisible="dialogVisible"
      @closeDialog="closeDialog"
    ></demonstrate>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import fotterBanner from "../components/fotterBanner.vue";
import { staClickMenu } from "../utils/index";
import demonstrate from "../components/demonstrate.vue";
export default {
  name: "HomeView",
  components: {
    mtop,
    mbottom,
    demonstrate,
    fotterBanner
  },
  data() {
    return {
      show: false,
      current: 0,
      current2: 0,
      caseNav: [],
      caseList: [],
      newsList: [],
      fqaList: [],
      page: 1,
      jqNewsBtns: [
        { id: "1", name: "新闻中心" },
        { id: "2", name: "行业动态" },
        { id: "3", name: "技术学堂" },
      ],
      swiperList: [],
      topList: [],
      dialogVisible: false,
    };
  },
  created() {
    // 获取轮播图
    this.getSwiperList();
    // 获取新闻列表
    this.getList();
    this.getList2();
  },
  mounted() {
    this.getCaseNav();
    // this.getNews();
    // this.getarticleList();
    this.initImgSwiper();
    this.initImgSwiper2();
    this.initImgSwiper3();
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    // 添加事件监听
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  computed: {
    getHot() {
      if (this.newsList.length > 0) {
        return this.newsList[0];
      } else {
        return [];
      }
    },
    getHotItem() {
      if (this.newsList.length > 2) {
        return this.newsList[1];
      } else {
        return [];
      }
    },
    getNewsList() {
      if (this.newsList.length > 3) {
        return this.newsList.filter((item, index) => {
          if (index > 1) {
            return item;
          }
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    // 统计菜单点击
    clickMenu(menuName,url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // 获取列表数据
    async getList() {
      // this.topList = []
      // this.newsList = []
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 2,
        typeId: `1,${Number(this.current2) + 2}`,
        status: "1",
        top: "0",
      });
      this.newsList = res.data.data.records;
      localStorage.removeItem("newsListdd");
      localStorage.setItem("newsListdd", JSON.stringify(this.newsList));
    },
    // 获取置顶数据
    async getList2() {
      // this.topList = []
      // this.newsList = []
      let res = await this.$http.post(this.$api.listArticle, {
        current: 1,
        size: 1,
        typeId: `1,${Number(this.current2) + 2}`,
        status: "1",
        top: "1",
      });
      this.topList = res.data.data.records;
      // localStorage.removeItem('newsListdd')
      // localStorage.setItem("newsListdd", JSON.stringify(this.topList));
    },
    async getSwiperList() {
      let res = await this.$http.post(this.$api.listSlideshow, {
        location: "1",
        status: "1",
      });
      this.swiperList = res.data.data || [
        {
          imgUrl: require("@/assets/sdImages/home/banner.png"),
          description:
            "智能客服满足客户对内队外全渠道智能服务场景，赋能企业降本增效，搭建智能客服体系",
        },
      ];
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
    toPagenews(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    tosomePage(row) {
      this.$router.push({
        path: row.button1Link,
      });
      // this.$router.push({
      //   name: row.button1Link,
      //   params: {
      //     detail: row.button1Link ? row.button1Link.split('?')[1] : '',
      //   },
      // });
    },
    tosomePage2(row) {
      this.dialogVisible = true
      this.clickMenu('免费试用')
      // this.$router.push({
      //   path: row.button2Link,
      // });
      // this.$router.push({
      //   name: row.button2Link,
      //   params: {
      //     detail: row.button2Link ? row.button2Link.split('?')[1] : '',
      //   },
      // });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toChange(e) {
      this.page = e;
    },
    toNews(item) {
      // sessionStorage.setItem("conid", 19);
      // this.toPage("/teamdetal");
      if(item.link){
        window.open(item.link, "_blank");
      }
    },
    toOpen(e) {
      window.open(e);
    },
    toPage(e) {
      this.$router.replace({ path: e });
    },
    //海量客户案例
    async getCaseNav() {
      this.caseNav = [
        { id: 1, name: "政务及公共服务" },
        { id: 2, name: "智慧农业" },
        { id: 3, name: "文化传媒" },
        { id: 4, name: "新零售" },
        { id: 5, name: "工业与能源" },
        { id: 6, name: "汽车出行" },
      ];
      this.$nextTick(() => {
        this.initCase();
      });
      // this.getCase();
      return;

      try {
        let {
          data: {
            code,
            data: { list },
          },
        } = await this.$http.get(this.$api.projectCate);
        // console.log(code, list);
        // this.caseNav = list;
        this.caseNav = [
          { id: 1, name: "政务及公共服务" },
          { id: 2, name: "智慧农业" },
          { id: 3, name: "文化传媒" },
          { id: 4, name: "新零售" },
          { id: 5, name: "工业与能源" },
          { id: 6, name: "汽车出行" },
        ];
        this.$nextTick(() => {
          this.initCase();
        });
        this.getCase();
      } catch (error) {}
    },
    async getCase() {
      try {
        let {
          data: {
            code,
            data: {
              list: { data },
            },
          },
        } = await this.$http.get(this.$api.casusList, {
          is_hot: this.current ? 2 : 1,
          project_cate_id: this.current
            ? this.caseNav[this.current - 1].id
            : "",
          page: 1,
          page_size: 3,
        });
        // console.log(data);
        this.caseList = data;
      } catch (error) {
        console.log(error);
      }
    },
    //新闻
    async getNews() {
      try {
        let {
          data: {
            code,
            data: {
              list: { new_list: data },
            },
          },
        } = await this.$http.get(this.$api.newsCenter);
        this.newsList = data;
      } catch (error) {}
    },
    async getarticleList() {
      try {
        let {
          data: {
            code,
            data: {
              list: { data },
            },
          },
        } = await this.$http.get(this.$api.articleList, {
          type: 2,
        });
        // console.log(data);
        this.fqaList = data;
      } catch (error) {}
    },
    initSwiper() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#banner", {
        loop: true, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //分页器按钮可点击
        },
        // autoplay: false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    initImgSwiper() {
      new Swiper("#imgSwiper", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
        },
        freeMode: true,
        speed: 3000,
      });
    },
    initImgSwiper2() {
      new Swiper("#imgSwiper2", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        // autoplay:false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: true, // 反向播放
        },
        freeMode: true,
        speed: 2000,
      });
    },
    initImgSwiper3() {
      new Swiper("#imgSwiper3", {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
        },
        freeMode: true,
        speed: 3000,
      });
    },
    initCase() {
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#case", {
        loop: false,
        breakpoints: {
          320: {
            //当屏幕宽度大于等于320
            slidesPerView: 2,
          },
          750: {
            //当屏幕宽度大于等于768
            slidesPerView: 8,
          },
        },
      });
    },
  },
  filters: {
    formatDay(e) {
      if (e) {
        return e.substr(8, 2);
      }
    },
    formatYear(e) {
      if (e) {
        return e.substr(0, 7);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #ffffff;

  .content {
    .box {
      width: 1200px;
      margin: 0 auto;
      padding: 100px 0;

      .btop {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0px auto 60px;

        .title {
          // font-size: 32px;
          // font-weight: 500;
          // color: #1D2129;
          // line-height: 45px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 32px;
          color: #1e1e1e;
          line-height: 48px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 13px;
        }

        .tips {
          // font-size: 16px;
          // font-weight: 400;
          // color: #646566;
          // line-height: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #8d8f94;
          line-height: 30px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .tscont {
        height: 536px;
        flex-direction: column;
      }

      .cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        // margin: 31px 0;
        .tsCard {
          background: url(~@/assets/sdImages/home/Rectangle10113.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard2 {
          background: url(~@/assets/sdImages/home/Rectangle10068.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard3 {
          background: url(~@/assets/sdImages/home/Rectangle10069.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard4 {
          background: url(~@/assets/sdImages/home/Rectangle10235.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard5 {
          background: url(~@/assets/sdImages/home/wenzikefu.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard6 {
          background: url(~@/assets/sdImages/home/Rectangle10094.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard7 {
          background: url(~@/assets/sdImages/home/siyouhua.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .tsCard8 {
          background: url(~@/assets/sdImages/home/Rectangle10095.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: 1;
        }

        .card {
          width: 290px;
          height: 250px;
          // background: linear-gradient(180deg, #F3F7FD 0%, #FFFFFF 100%);
          // box-shadow: 0px 0px 20px 0px rgba(16, 38, 80, 0.1);
          border-radius: 2px;
          // border: 1px solid #EBEDF0;
          // padding: 25px;
          // box-sizing: border-box;
          position: relative;
          // margin-bottom: 16px;
          // transform: translateY(-16px);
          overflow: hidden;

          img {
            width: 100%;
          }

          .titleAndBtn {
            width: 100%;
            text-align: center;
            margin-top: 179px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #ffffff;
            line-height: 30px;
            font-style: normal;
            text-transform: none;

            img {
              width: 16px;
              height: 16px;
            }
          }

          .header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ebedf0;
            padding: 0px 0 13px;
            margin-bottom: 13px;

            .ico {
              width: 36px;
              height: 36px;
            }

            .title {
              font-size: 18px;
              font-weight: 500;
              color: #1d2129;
              line-height: 25px;
              margin-left: 10px;
            }
          }

          .cbox {
            // font-size: 14px;
            // font-weight: 400;
            // color: #646566;
            // line-height: 20px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // height: 114px;

            // .btn {
            // 	width: 100px;
            // 	height: 38px;
            // 	background: #FFFFFF;
            // 	box-shadow: 0px 5px 20px 0px rgba(12, 65, 189, 0.1);
            // 	border-radius: 2px;
            // 	border: 1px solid #2B6BFF;
            // 	font-size: 14px;
            // 	font-family: PingFangSC-Medium, PingFang SC;
            // 	font-weight: 500;
            // 	color: #2B6BFF;
            // 	line-height: 20px;
            // 	text-shadow: 0px 5px 20px rgba(12, 65, 189, 0.1);
            // 	display: flex;
            // 	align-items: center;
            // 	justify-content: center;
            // 	display: none;
            // 	cursor: pointer;
            // }
            width: 100%;
            height: 286px;
            background: linear-gradient(
              180deg,
              rgba(58, 137, 254, 0.8) 0%,
              #0768fe 100%
            );
            border-radius: 6px 6px 6px 6px;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;

            .cbox-content {
              width: 80%;
              margin: 36px auto 36px auto;
              opacity: 1;

              .btns {
                display: flex;
                justify-content: center;
                transition: all 0.3s ease-in-out;

                .free-btn {
                  width: 207px;
                  background: #ffffff;
                  border-radius: 4px 4px 4px 4px;
                  border: 1px solid #3a89fe;
                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 14px;
                  color: #1973fc;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  box-sizing: border-box;
                  margin-right: 10px;
                  height: 34px;
                  line-height: 34px;
                  cursor: pointer;
                }

                .detail-btn {
                  width: 90px;
                  border-radius: 4px 4px 4px 4px;
                  border: 1px solid #ffffff;
                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 14px;
                  color: #ffffff;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  box-sizing: border-box;
                  height: 34px;
                  line-height: 34px;
                  cursor: pointer;
                }
              }

              img {
                width: 16px;
                height: 16px;
                position: absolute;
                bottom: 29px;
              }

              .p1 {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 22px;
                color: #ffffff;
                line-height: 30px;
                font-style: normal;
                text-transform: none;
                margin-bottom: 19px;
              }

              .p2 {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                line-height: 26px;
                font-style: normal;
                text-transform: none;
                text-align: left;
                height: 130px;
              }
            }
          }

          // .cbox:hover {
          // 	span {
          // 		display: none;
          // 	}

          // 	.btn {
          // 		display: flex;
          // 		transition: all 0.3s;
          // 	}
          // }
        }

        .upCard {
          transform: translateY(12px);
        }

        .downCard {
          transform: translateY(-8px);
        }

        .upCard:hover {
          height: 266px;
          transform: translateY(-4px);
          transition: all 0.5s ease-in-out;
        }

        .downCard:hover {
          height: 266px;
          transform: translateY(8px);
          transition: all 0.5s ease-in-out;
        }

        .upCard:hover .cbox {
          display: block;
          animation: slideUp 0.5s forwards;
        }

        .downCard:hover .cbox {
          display: block;
          animation: downslideUp 0.5s forwards;
        }

        @keyframes slideUp {
          0% {
            height: 0;
            top: 250px;
          }

          100% {
            height: 300px;
            top: -17px;
          }
        }

        @keyframes downslideUp {
          0% {
            height: 0;
            bottom: 250px;
          }

          100% {
            height: 286px;
            bottom: -30px;
          }
        }

        .card:hover .titleAndBtn {
          display: none;
        }

        .card::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(~@/assets/sdImages/home/Rectangle10236.png) no-repeat;
          background-size: cover;
          background-position: center center;
          z-index: -1;
        }

        .card2 {
          width: 373px;
          height: 113px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 6px;
          border: 2px solid #ffffff;
          backdrop-filter: blur(4px);
          padding: 20px 30px;
          box-sizing: border-box;
          margin-bottom: 32px;

          .header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .ico {
              width: 26px;
              height: 26px;
            }

            .title {
              font-size: 18px;
              font-weight: 500;
              color: #1d2129;
              line-height: 25px;
              margin-left: 6px;
            }
          }

          .cbox {
            font-size: 14px;
            font-weight: 400;
            color: #646566;
            line-height: 20px;
          }
        }

        .sollcor {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          // overflow-x: auto;
          .left,
          .right {
            width: 28px;
            height: 28px;
            border-radius: 100%;
            position: absolute;
            z-index: 99;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              cursor: pointer;
            }
          }

          .left {
            left: -45px;
          }

          .right {
            right: -45px;
          }
        }

        .slide {
          width: 100%;
          overflow: auto;
          display: flex;
          align-items: center;
          min-width: 1200px;
        }

        .card3 {
          width: 384px;
          min-width: 384px;
          height: 166px;
          background: #ffffff;
          box-shadow: 0px 0px 20px 0px rgba(16, 38, 80, 0.1);
          border-radius: 2px;
          padding: 32px;
          box-sizing: border-box;

          .cbox {
            display: flex;
            align-items: flex-start;

            .cico {
              width: 32px;
              height: 32px;
              background: #ffffff;
              border-radius: 2px;

              // border: 1px solid #EBEDF0;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .txt {
              flex: 1;
              font-size: 14px;
              font-weight: 400;
              color: #646566;
              line-height: 20px;
              margin-left: 16px;
              min-height: 80px;
              margin-top: 2px;
            }
          }

          .cbotttom {
            width: 100%;
            margin-top: 4px;
            text-align: right;
          }
        }

        .m24 {
          margin: 0 24px;
        }

        .abg-radio {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .abg-nav {
            border-radius: 42px 42px 42px 42px;
            border: 1px solid #c1c5ca;
            padding: 12px 40px 12px 40px;
            margin-right: 24px;
            // font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #1e1e1e;
            font-style: normal;
            text-transform: none;
            cursor: pointer;
          }

          .active {
            background: #ffffff;
            border: 1px solid #5398fe;
          }
        }

        .abg-content {
          display: flex;
          // margin-top: 60px;

          .abg-left {
            margin-right: 24px;
            // background: #ffffff;
            // height: 500px;
            border-radius: 12px 12px 0px 0px;

            .img {
              width: 522px;
              height: 222px;
              border-radius: 12px 12px 0px 0px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                // transition: transform 0.3s ease;
                transition: 0.35s;
                object-fit: cover;
              }
            }

            .abg-left-content {
              cursor: pointer;
              background: #ffffff;
              border-radius: 0px 0px 12px 12px;
              padding: 16px 25px 18px 25px;
              height: 137px;
              box-sizing: border-box;

              .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 8px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              .content {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              .time {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 14px;
              }
            }
          }

          .abg-left img:hover {
            transform: scale(1.1);
          }

          .abg-right {
            .abg-right-content {
              cursor: pointer;
              width: 650px;
              height: 169px;
              padding: 20px;
              background: #ffffff;
              border-radius: 12px 12px 12px 12px;
              box-sizing: border-box;
              margin-bottom: 20px;

              .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }

              .content {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #8d8f94;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 20px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }

              .time-more {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .time {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #8d8f94;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                }

                .more {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #8d8f94;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                  display: flex;
                  align-items: center;

                  .group14 {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                  }

                  .group13 {
                    width: 20px;
                    height: 20px;
                    margin-left: 5px;
                    display: none;
                  }
                }
              }
            }
          }

          .abg-right-content:hover {
            border-bottom: 2px solid #3a89fe;

            .title {
              color: #3a89fe;
            }

            .time-more {
              .more {
                color: #3a89fe;

                .group14 {
                  display: none;
                }

                .group13 {
                  display: block;
                }
              }
            }
          }

          .abg-left-content:hover {
            border-bottom: 2px solid #3a89fe;

            .title {
              color: #3a89fe;
            }
          }
        }
      }
    }
  }



  .hbg {
    background: #deebff;
  }

  .abg {
    // background: url(~@/assets/ximg/bj-wsmxzwm@2x.png) no-repeat;
    // background-size: cover;
    // background-position: center center;
    background: #f6f6f6;
  }

  .hzbg {
    // background: url(~@/assets/ximg/background-parter@2x.png) no-repeat;
    // background-size: cover;
    // background-position: center center;
    background: #ffffff;
  }
}

.ish5 {
  .content {
    .box {
      width: auto;
      padding: 32px;

      .btop {
        .title {
          font-size: 36px;
        }

        .tips {
          font-size: 20px;
          line-height: 38px;
        }
      }
      .tscont {
        height: auto;
        flex-direction: row;
      }

      .cont {
        .card {
          width: calc(100% - 16px);
          height: 381px;

          .header {
            .title {
              font-size: 26px;
              white-space: nowrap;
            }
          }

          .cbox {
            height: 381px;
            font-size: 22px;
            line-height: 32px;

            .btn {
              padding: 12px;
            }
          }
        }

        .card2 {
          width: calc(50% - 16px);
          height: 228px;

          .header {
            .title {
              font-size: 26px;
            }
          }

          .cbox {
            font-size: 22px;
            line-height: 38px;
          }
        }

        .sollcor {
          // overflow: auto;
          height: 100%;
          flex-direction: column;

          .left,
          .right {
            display: none;
          }

          .slide {
            flex-direction: column;
            min-width: auto;
          }

          .card3 {
            width: 680px;
            height: auto;
            margin: 12px;

            .cbox {
              .txt {
                font-size: 26px;
                line-height: 32px;
              }
            }
          }
        }
        .abg-content {
          display: block;
          .abg-left {
            margin-bottom: 20px;
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .banner {
    font-size: 0;
    height: 714px;

    .swiper-slide {
      position: relative;

      .banimg {
        max-width: 100%;
        min-width: 1200px;
        width: 100%;
        position: absolute;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
        top: 32%;

        .title {
          width: 1200px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 36px;
          color: #2b2c2f;
          font-style: normal;
          text-transform: none;
        }

        .tip {
          width: 1200px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #2b2c2f;
          line-height: 26px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 23px;
        }

        .btn-box {
          width: 1200px;
          margin-top: 180px;
          display: flex;

          .btn {
            width: 144px;
            height: 49px;
            background: #3a89fe;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #3a89fe;
            cursor: pointer;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            box-sizing: border-box;
            margin-right: 16px;
          }

          .btn2 {
            width: 99px;
            height: 49px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #3a89fe;
            cursor: pointer;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 18px;
            color: #3a89fe;
            line-height: 49px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            box-sizing: border-box;
          }

          .btn:hover {
            opacity: 0.9;
          }

          .btn2:hover {
            opacity: 0.9;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 714px;
      object-fit: cover;
    }

    :deep(.swiper-pagination-bullets) {
      // left: -35%;
      bottom: 100px;
      display: flex;
      width: 1200px;
      left: 0;
      right: 0;
      margin: auto;
    }

    :deep(.swiper-pagination-bullet) {
      width: 36px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      display: inline-block;
      border-radius: 0%;
    }

    :deep(.swiper-pagination-bullet-active) {
      width: 36px;
      height: 3px;
      opacity: 1;
      // background: #3A89FE;
      background: #ffffff;
      position: relative;
    }

    :deep(.swiper-pagination-bullet-active)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 3px;
      background: #3a89fe;
      /* 覆盖层颜色 */
      z-index: 1;
      animation: revealColor 5s forwards;
      /* 动画名称、持续时间和填充模式 */
    }

    @keyframes revealColor {
      from {
        width: 0%;
        /* 动画结束时，覆盖层宽度为0，暴露出下面的颜色 */
      }
    }
  }

  .product-nav {
    position: relative;
    z-index: 2;

    // margin-top: -30px;
    .Gwidth {
      margin: 20px auto;
    }

    li {
      box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
      padding: 24px 50px 24px 90px;
      margin-bottom: 10px;
      position: relative;

      a {
        font-size: 28px;
        font-weight: 400;
        color: #969799;

        h4 {
          font-size: 32px;
          font-weight: 500;
          color: #1d2129;
          line-height: 25px;
          text-shadow: 0px 2px 10px rgba(29, 33, 41, 0.1);
          // background: url(~@/assets/ico/icon-jiantou.png) right center no-repeat;
          background-size: 12px 12px;
          margin-bottom: 10px;
        }

        .more {
          position: absolute;
          opacity: 0;
          left: 0;
          bottom: 0;
          transition: 0.35s;
        }
      }

      &:nth-child(1) {
        // background: #ffffff url(~@/assets/ico/icon-nft.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }

      &:nth-child(2) {
        // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }

      &:nth-child(3) {
        // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 50px 17px no-repeat;
        background-size: 38px 38px;
      }
    }
  }

  .Gtitle {
    font-size: 38px;
    font-weight: 500;
    color: #1d2129;
    text-align: center;
    margin: 60px 0;
  }

  .case {
    // padding: 20px 0;
    // background-color: #f5f6fa;

    &-tab {
      display: flex;
      justify-content: center;

      // border-bottom: 1px solid #dcdee0;
      :deep(.swiper-slide) {
        margin-right: 14px;
      }

      .case-nav {
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #4f5861;
        font-style: normal;
        text-transform: none;
        background: #f2f7ff;
        border-radius: 40px 40px 40px 40px;
        padding: 14px 0;
        cursor: pointer;

        &.active {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #1e1e1e;
          font-style: normal;
          text-transform: none;
          background: #ffffff;
          border-radius: 100px 100px 100px 100px;
          border: 2px solid #3181f6;
        }
      }
    }

    &-content {
      &-text {
        margin-top: 74px;
        margin-right: 69px;

        .p1 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 32px;
          color: #15191d;
          line-height: 44px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 18px;
        }

        .p1:hover {
          color: #3a89fe;
        }

        .p2 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #4f5861;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 28px;
        }

        .p3 {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #3181f6;
          line-height: 26px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 52px;
          cursor: pointer;

          img {
            width: 21px;
            height: 18px;
            margin-left: 6px;
          }
        }

        .p3:hover img {
          animation: slideInLeft 1s forwards;
        }

        @keyframes slideInLeft {
          from {
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
          }

          to {
            transform: translate3d(0, 0, 0);
          }
        }

        .attr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width: 65%;

          .attr-info {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 25px;
            width: 50%;

            img {
              width: 23px;
              height: 23px;
              margin-right: 10px;
            }

            .title {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #1e1e1e;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }
      }

      &-img {
        margin-top: 74px;
        width: 646px;
        height: 382px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 22px;

        img {
          width: 100%;
          height: 100%;
          // transition: transform 0.3s ease;
          transition: 0.35s;
          object-fit: cover;
          border-radius: 22px;
        }
      }

      &-img img:hover {
        transform: scale(1.1);
      }

      &-item {
        width: 100%;
        background: #ffffff;
        border-radius: 2px;
        margin-top: 20px;
        transition: 0.35s;

        &-img {
          font-size: 0;
          overflow: hidden;

          img {
            width: 100%;
            transition: 0.35s;
            object-fit: cover;
          }
        }

        &-cont {
          padding: 30px 30px 40px 30px;
          box-sizing: border-box;

          &-title {
            font-size: 28px;
            font-weight: 500;
            color: #1d2129;
          }

          &-des {
            font-size: 28px;
            line-height: 36px;
            font-weight: 400;
            color: #646566;
            margin-top: 8px;
          }

          &-tags {
            margin-top: 24px;

            span {
              padding: 4px 14px;
              display: inline-block;
              font-size: 26px;
              font-weight: 400;
              color: #2b6bff;
              // line-height: 20px;
              background: #f2f3f5;
              border-radius: 2px;
              margin: 0 8px 8px 0;
            }
          }
        }
      }
    }

    &-more {
      width: 180px;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #2b6bff;
      text-align: center;
      margin: 50px auto 0 auto;

      a {
        font-size: 28px;
        font-weight: 400;
        color: #2b6bff;
        line-height: 40px;
      }
    }
  }

  .home-more {
    padding: 20px 0;

    &-content {
      display: flex;
      flex-wrap: wrap;

      &-item {
        transition: 0.35s;
        width: calc(50% - 14px);
        margin-right: 24px;
        background: #ffffff;
        box-shadow: 0px 0px 85px 0px rgba(29, 33, 41, 0.05);
        border-radius: 2px;
        border: 1px solid #ebedf0;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:last-child {
          align-items: center;

          .home-more-content-item-ico {
            img {
              width: 32px;
            }
          }

          h5 {
            font-weight: 400;
            color: #2b6bff;
          }
        }

        &-ico {
          img {
            width: 55px;
          }
        }

        h5 {
          font-size: 28px;
          font-weight: 600;
          color: #1d2129;
          line-height: 28px;
        }

        &-des {
          font-size: 28px;
          font-weight: 400;
          color: #646566;
          line-height: 34px;
          margin-top: 8px;
        }
      }
    }
  }

  .about-us {
    // background: url(~@/assets/images/bj-wsmxzwm.png) center/cover no-repeat;
    padding: 20px 0;

    &-header {
      &-title {
        font-size: 38px;
        font-weight: 500;
        color: #1d2129;
      }

      &-des {
        font-size: 24px;
        font-weight: 400;
        color: #323233;
        margin-top: 5px;
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: 33.33%;
        text-align: center;

        &-ico {
          font-size: 0;

          img {
            width: 100px;
          }
        }

        &-title {
          span {
            font-size: 28px;
            font-weight: 600;
            color: #1d2129;

            &:nth-child(1) {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  .swiper-container1 > .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }

  .swiper-container1 {
    overflow: visible;
  }

  .relative-filter {
    position: relative;

    .left-zg {
      position: absolute;
      top: 0;
      left: 0;
      width: 240px;
      height: 100%;
      background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 99;
    }

    .right-zg {
      position: absolute;
      top: 0;
      right: 0;
      width: 240px;
      height: 100%;
      background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
      z-index: 99;
    }
  }

  .partner {
    padding: 10px 0;
    background: #fff;

    .imgBox {
      // width: 50%;
      width: 180px;
      height: 82px;
      background: #ffffff;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: skewX(-16deg);
      font-size: 0;
      text-align: center;
      position: relative;
      z-index: 1;
      // padding: 10px 0;
      margin: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      // &::after {
      // 	content: "";
      // 	border: 1px solid #ebedf0;
      // 	width: 200%;
      // 	height: 200%;
      // 	transform-origin: 0 0;
      // 	transform: scale(0.5);
      // 	position: absolute;
      // 	left: 0;
      // 	top: 0;
      // 	transition: 0.35s;
      // }

      // transition: 0.35s;
    }

    img {
      max-width: 172px;
      transform: skewX(15deg);
      width: 128px;
      height: 55px;
      object-fit: cover;
    }

    &-cont {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      background: #fff;

      // &::after {
      // 	content: "";
      // 	border: 1px solid #ebedf0;
      // 	width: 200%;
      // 	height: 200%;
      // 	transform-origin: 0 0;
      // 	transform: scale(0.5);
      // 	position: absolute;
      // 	left: 0;
      // 	top: 0;
      // }

      .imgBox {
        // width: 50%;
        width: 180px;
        height: 82px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transform: skewX(-16deg);
        font-size: 0;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 10px 0;
        margin: 10px;

        // &::after {
        // 	content: "";
        // 	border: 1px solid #ebedf0;
        // 	width: 200%;
        // 	height: 200%;
        // 	transform-origin: 0 0;
        // 	transform: scale(0.5);
        // 	position: absolute;
        // 	left: 0;
        // 	top: 0;
        // 	transition: 0.35s;
        // }

        // transition: 0.35s;
      }

      img {
        max-width: 172px;
        transform: skewX(15deg);
        width: 80%;
        height: 80%;
      }
    }
  }

  .news {
    background: #f5f6fa;
    padding: 20px 0;

    &-content {
      &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        color: #333333;
        font-size: 32px;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20px;

        &-left,
        &-right {
          line-height: 40px;
          display: none;
          padding: 0 5px;
        }

        &-left {
          display: block;
          border-bottom: 3px solid #2b6bff;
        }
      }

      &-cont {
        .news-hot {
          font-size: 0;
          position: relative;
          margin-bottom: 10px;

          img {
            width: 100%;
          }

          &-cont {
            background: rgba(#000000, 0.88);
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            padding: 10px 20px;
            box-sizing: border-box;

            &-title {
              font-size: 28px;
              color: #ffffff;
            }

            &-des {
              font-size: 24px;
              color: #ffffff;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .news-newlist {
          background: #ffffff;
          box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
          box-sizing: border-box;
          padding: 16px 21px;
          margin-bottom: 10px;
          position: relative;

          &::after {
            content: "";
            width: 16px;
            height: 14px;
            // background: url(~@/assets/ico/back.png) center center no-repeat;
            background-size: 16px 14px;
            position: absolute;
            right: 21px;
            bottom: 16px;
          }

          &-top {
            display: flex;

            &-left {
              margin-right: 15px;
              text-align: center;

              &-day {
                font-size: 38px;
                font-weight: 600;
                color: #333333;
              }

              &-year {
                font-size: 28px;
                font-weight: 400;
                color: #999999;
                white-space: nowrap;
                margin-top: 14px;
                position: relative;

                &::after {
                  content: "";
                  width: 20px;
                  height: 2px;
                  background-color: #333333;
                  position: absolute;
                  left: 50%;
                  top: -10px;
                  transform: translateX(-50%);
                }
              }
            }

            &-right {
              &-title {
                font-size: 32px;
                color: #333333;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
              }

              &-des {
                margin-top: 10px;
                font-size: 28px;
                color: #666666;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }

          &-tags {
            font-size: 28px;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;

            span {
              margin-right: 5px;
              margin-bottom: 5px;
            }
          }
        }

        .fqa {
          background: #ffffff;
          box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
          box-sizing: border-box;
          padding: 20px;
          color: #333333;

          li {
            a {
              // background: url(~@/assets/ico/jt1.png) left center no-repeat;
              background-size: 6px 11px;
              padding-left: 15px;
              display: block;
              font-size: 32px;
              font-weight: 400;
              color: #333333;
              text-align: left;
              line-height: 44px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 751px) {
  .htop {
    // height: 70px;
  }

  .case {
    &-tab {
      ul {
        li {
          padding: 22px 26px;
        }
      }

      .case-nav {
        font-size: 18px;

        &.active {
          font-size: 18px;
        }
      }
    }

    &-content {
      display: flex;
      // flex-wrap: wrap;

      &-head {
        font-size: 18px;
      }

      &-item {
        width: calc(100% / 2 - 10px);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &-cont {
          &-title {
            font-size: 16px;
          }

          &-des {
            line-height: 22px;
            font-size: 16px;
          }

          &-tags {
            span {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }

    &-more {
      width: 144px;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #2b6bff;
      text-align: center;
      margin: 50px auto 0 auto;

      a {
        font-size: 16px;
        font-weight: 400;
        color: #2b6bff;
        line-height: 40px;
      }
    }
  }

  // .partner {
  // 	&-cont {
  // 		.img {
  // 			width: calc(100% / 3);
  // 		}
  // 	}
  // }
}

@media screen and (min-width: 870px) {
  .product-nav {
    position: relative;
    z-index: 2;
    margin-top: 0;

    ul {
      box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
      background-color: #ffffff;
      position: absolute;
      left: 50%;
      bottom: -60px;
      transform: translateX(-50%);

      li {
        flex: 1;
        padding: 24px 10px 24px 50px;
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
        width: 33.33%;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;

        a {
          font-size: 14px;

          h4 {
            font-size: 18px;
          }

          p {
            min-height: 36px;
          }
        }

        &:nth-child(1) {
          // background: #ffffff url(~@/assets/ico/icon-nft.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(2) {
          left: 33.33%;
          // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(3) {
          left: 66.66%;
          // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 10px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:hover {
          a {
            color: #abc4ff;

            .more,
            h4 {
              color: #ffffff;
              background: none;
            }

            .more {
              position: relative;
              opacity: 1;
              margin-top: 27px;
            }
          }

          &:nth-child(1) {
            // background: #2b6bff url(~@/assets/ico/icon-nft2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(2) {
            // background: #2b6bff url(~@/assets/ico/icon-yuanyuzhou2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(3) {
            // background: #2b6bff url(~@/assets/ico/icon-kaifa2.png) 10px 17px no-repeat;
            background-size: 38px 38px;
          }
        }
      }
    }
  }

  .about-us {
    padding: 40px 0;

    &-header {
      &-title {
        font-size: 24px;
        font-weight: 500;
        color: #1d2129;
      }

      &-des {
        font-size: 16px;
        font-weight: 400;
        color: #323233;
        margin-top: 5px;
      }
    }

    &-content {
      margin-top: 80px;

      &-item {
        width: 20%;
      }

      &-title {
        span {
          font-size: 14px;
          font-weight: 600;
          color: #1d2129;

          &:nth-child(1) {
            font-size: 30px;
          }
        }
      }
    }
  }

  // .partner {
  // 	padding: 40px 0 0;

  // 	&-cont {
  // 		margin-top: 40px;

  // 		.img {
  // 			width: 25%;
  // 		}
  // 	}
  // }

  .news {
    padding: 40px 0;

    &-content {
      &-head {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .case {
    &-content {
      &-item {
        width: calc(100% / 3 - 10px);
        margin-right: 15px;
        margin-top: 40px;

        &:hover {
          box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);

          .case-content-item-img {
            overflow: hidden;

            img {
              transform: scale(1.2);
            }
          }
        }

        &:nth-child(2n) {
          margin-right: 15px;
        }

        &:nth-child(3n) {
          margin-right: 0px;
        }

        &-cont {
          padding: 20px;
        }
      }
    }

    &-more {
      &:hover {
        background-color: #2b6bff;

        a {
          color: #ffffff;
        }
      }
    }
  }

  .home-more {
    padding: 40px 0;

    &-content {
      margin-top: 40px;

      &-item {
        width: calc(33.33% - 14px);
        margin-right: 16px;
        padding: 24px;

        &-des {
          font-size: 16px;
        }

        &:hover {
          transform: translateY(-16px);
        }

        &:nth-child(2n) {
          margin-right: 16px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        h5 {
          font-size: 16px;
          font-weight: 600;
          color: #1d2129;
          line-height: 22px;
        }

        &-des {
          font-size: 14px;
          font-weight: 400;
          color: #646566;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }

  // .partner {
  // 	padding: 40px 0 0;

  // 	&-cont {
  // 		.img {
  // 			&:hover {
  // 				box-shadow: 0px 1px 16px 0px rgba(33, 86, 210, 0.15);

  // 				&::after {
  // 					border: 2px solid #2b6bff;
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  .news {
    padding: 40px 0;

    &-content {
      &-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 389px;

          .news-hot {
            overflow: hidden;

            img {
              transition: 0.35s;
            }

            &:hover {
              img {
                transform: scale(1.2);
              }
            }

            &-cont {
              background: rgba(#000000, 0.88);
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
              padding: 10px 20px;
              box-sizing: border-box;

              &-title {
                font-size: 14px;
                color: #ffffff;
              }

              &-des {
                font-size: 12px;
                color: #ffffff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .news-newlist {
            transition: 0.35s;

            &:hover {
              transform: scale(0.98);

              .news-newlist-top {
                .news-newlist-top-right {
                  .news-newlist-top-right-title {
                    color: #2b6bff;
                  }
                }
              }
            }

            &-top {
              display: flex;

              &-left {
                margin-right: 15px;
                text-align: center;

                &-day {
                  font-size: 24px;
                  font-weight: 600;
                  color: #333333;
                }

                &-year {
                  font-size: 14px;
                  font-weight: 400;
                  color: #999999;
                  white-space: nowrap;
                  margin-top: 14px;
                  position: relative;

                  &::after {
                    content: "";
                    width: 20px;
                    height: 2px;
                    background-color: #333333;
                    position: absolute;
                    left: 50%;
                    top: -10px;
                    transform: translateX(-50%);
                  }
                }
              }

              &-right {
                &-title {
                  font-size: 16px;
                  color: #333333;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                }

                &-des {
                  margin-top: 10px;
                  font-size: 14px;
                  color: #666666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }

            &-tags {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              margin-top: 10px;

              span {
                margin-right: 5px;
                margin-bottom: 5px;
              }
            }
          }

          .fqa {
            li {
              a {
                font-size: 16px;
              }
            }

            li:hover {
              a {
                color: #2b6bff;
                font-size: 16px;
              }
            }
          }

          &:nth-child(2) {
            width: calc(100% - 400px);
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .product-nav {
    ul {
      li {
        padding: 24px 50px 24px 90px;

        a {
          font-size: 14px;
        }

        &:nth-child(1) {
          // background: #ffffff url(~@/assets/ico/icon-nft.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(2) {
          // background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:nth-child(3) {
          // background: #ffffff url(~@/assets/ico/icon-kaifa.png) 50px 17px no-repeat;
          background-size: 38px 38px;
        }

        &:hover {
          &:nth-child(1) {
            // background: #2b6bff url(~@/assets/ico/icon-nft2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(2) {
            // background: #2b6bff url(~@/assets/ico/icon-yuanyuzhou2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }

          &:nth-child(3) {
            // background: #2b6bff url(~@/assets/ico/icon-kaifa2.png) 50px 17px no-repeat;
            background-size: 38px 38px;
          }
        }
      }
    }
  }

  .Gtitle {
    font-size: 32px;
    font-weight: 500;
    color: #1d2129;
    line-height: 45px;
    margin: 60px auto;
    margin-bottom: 63px;
  }

  .case {
    &-content {
      &-item {
        width: calc(100% / 3 - 16px);
        margin-right: 24px;
        margin-top: 60px;

        &:nth-child(2n) {
          margin-right: 24px;
        }

        &:nth-child(3n) {
          margin-right: 0px;
        }

        &-cont {
          padding: 30px 30px 40px 30px;
        }
      }
    }
  }

  .home-more {
    padding: 80px 0;

    &-content {
      margin-top: 60px;

      &-item {
        width: calc(25% - 18px);
        margin-right: 24px;

        &:nth-child(2n) {
          margin-right: 24px;
        }

        &:nth-child(3n) {
          margin-right: 24px;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .about-us {
    padding: 90px 0 100px 0;

    &-header {
      &-title {
        font-size: 32px;
        font-weight: 600;
      }

      &-des {
        margin-top: 14px;
      }
    }

    &-content {
      // margin-top: 135px;
      &-item {
        &-title {
          span {
            font-size: 16px;

            &:nth-child(1) {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  // .partner {
  // 	padding: 80px 0 0;
  // }

  .news {
    padding: 80px 0;

    &-content {
      &-head {
        &-right {
          display: block;
          width: 389px;
        }
      }

      &-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
          width: 389px;

          .news-newlist {
            padding: 10px 21px;
            height: 127px;
            box-sizing: border-box;

            &-top {
              &-right {
                width: calc(100% - 60px);

                &-title {
                  font-size: 16px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                &-des {
                  font-size: 12px;
                  margin-top: 5px;
                }
              }
            }

            &-tags {
              margin-top: 5px;
            }
          }

          .news-hot {
            img {
              height: 264px;
            }
          }

          &:nth-child(2) {
            width: 389px;
          }

          &:nth-child(3) {
            width: 389px;

            .news-content-head {
              display: none;
            }
          }

          .fqa {
            li {
              a {
                line-height: 45px;
              }
            }
          }
        }
      }
    }
  }
}

.ish5 {
  .banner {
    font-size: 0;

    .swiper-slide {
      position: relative;

      .banimg {
        width: 100%;
        position: absolute;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
        top: 32%;

        .title {
          width: 100%;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 36px;
          color: #2b2c2f;
          font-style: normal;
          text-transform: none;
        }

        .tip {
          width: 100%;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #2b2c2f;
          line-height: 26px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 23px;
        }

        .btn-box {
          width: 100%;
          margin-top: 180px;
          display: flex;

          .btn {
            width: 144px;
            height: 49px;
            background: #3a89fe;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #3a89fe;
            cursor: pointer;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            line-height: 49px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            box-sizing: border-box;
            margin-right: 16px;
          }

          .btn2 {
            width: 99px;
            height: 49px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #3a89fe;
            cursor: pointer;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 18px;
            color: #3a89fe;
            line-height: 49px;
            text-align: center;
            font-style: normal;
            text-transform: none;
            box-sizing: border-box;
          }

          .btn:hover {
            opacity: 0.9;
          }

          .btn2:hover {
            opacity: 0.9;
          }
        }
      }
    }

    img {
      width: 100%;
      object-position: 90%;
    }

    :deep(.swiper-pagination-bullets) {
      // left: -35%;
      bottom: 100px;
      display: flex;
      width: 1200px;
      left: 0;
      right: 0;
      margin: auto;
    }

    :deep(.swiper-pagination-bullet) {
      width: 36px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      display: inline-block;
      border-radius: 0%;
    }

    :deep(.swiper-pagination-bullet-active) {
      width: 36px;
      height: 3px;
      opacity: 1;
      // background: #3A89FE;
      background: #ffffff;
      position: relative;
    }

    :deep(.swiper-pagination-bullet-active)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 3px;
      background: #3a89fe;
      /* 覆盖层颜色 */
      z-index: 1;
      animation: revealColor 5s forwards;
      /* 动画名称、持续时间和填充模式 */
    }

    @keyframes revealColor {
      from {
        width: 0%;
        /* 动画结束时，覆盖层宽度为0，暴露出下面的颜色 */
      }
    }
  }

}
</style>
